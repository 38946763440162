<template>
	<div style="margin:20px 25px 25px 25px">
		<div class="text-h3" :style="{'font-weight':400,color: theme.title_color, 'letter-spacing': '0.4vw !important;', 'margin':'0px 0 30px 0'}"><v-icon :style="{'font-size':'50px', 'margin':'-8px 10px 0 10px', color:theme.title_color}">mdi-book-open-variant</v-icon>Oppskrifter</div>
		<v-row>
			<v-col v-for="recipe in recipes" 
			v-bind:key="recipe.title"
			lg="4"
			md="6"
			sm="12" >
				<v-card :to="{ name: 'show-recipe', params: { recipe: recipe.title } }">
					<v-img
						height="200"
						:src="'https://assets.brukmat.no/images/'+recipe.img_url"
					></v-img>
					<v-card-title class="text-capitalize">{{recipe.title}}</v-card-title>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>
<script type="text/javascript">
	import axios from 'axios';
	export default {
		data (){
			return {
				recipes:[]
			};
		},
		beforeCreate:function(){
			axios.get('https://api.brukmat.no/recipes/all?page=0').then((val)=>{
				console.log(val);
				this.recipes = val.data;
			});
		},
		computed: {
			theme(){
				return this.$vuetify.theme.themes[(this.$vuetify.theme.dark) ? 'dark' : 'light'];
			}
		}
	}
</script>